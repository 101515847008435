import { ChangeEvent, useMemo, useState } from 'react';
import { ScheduleKidsListBeingShowType } from '../types';
import useLoadSchedules from './functions/useLoadSchedules';

export default function useSchedules() {
  const dates = useMemo(() => {
    const today = new Date();
    today.setHours(today.getHours() - 3);
    const todayFormatted = today.toJSON().slice(0, 10);
    const currentDay = todayFormatted.split('-')[2];
    const currentMonth = todayFormatted.split('-')[1];
    const currentYear = todayFormatted.split('-')[0];
    const currentDate = `${currentDay}/${currentMonth}/${currentYear}`;

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toJSON().slice(0, 10);
    const tomorrowDay = tomorrowFormatted.split('-')[2];
    const tomorrowMonth = tomorrowFormatted.split('-')[1];
    const tomorrowYear = tomorrowFormatted.split('-')[0];
    const tomorrowDate = `${tomorrowDay}/${tomorrowMonth}/${tomorrowYear}`;

    const afterTomorrow = new Date(today);
    afterTomorrow.setDate(afterTomorrow.getDate() + 2);
    const afterTomorrowFormatted = afterTomorrow.toJSON().slice(0, 10);
    const afterTomorrowDay = afterTomorrowFormatted.split('-')[2];
    const afterTomorrowMonth = afterTomorrowFormatted.split('-')[1];
    const afterTomorrowYear = afterTomorrowFormatted.split('-')[0];
    const afterTomorrowDate = `${afterTomorrowDay}/${afterTomorrowMonth}/${afterTomorrowYear}`;

    return {
      currentDate,
      tomorrowDate,
      afterTomorrowDate,
    };
  }, []);

  const [specificDateModalShow, setSpecificDateModalShow] = useState(false);
  const [kidsModalShow, setKidsModalShow] = useState(false);
  const [warningsModalShow, setWarningsModalShow] = useState(false);
  const [scheduleKidsListBeingShow, setScheduleKidsListBeingShow] = useState<
    ScheduleKidsListBeingShowType
  >({} as ScheduleKidsListBeingShowType);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedActivity, setSelectedActitvity] = useState('');

  const {
    schedules,
    getScheduleList,
    isLoading,
    doesListApiHasError,
    dateBeingShown,
    activitiesList,
    warnings,
  } = useLoadSchedules({
    dates,
  });

  const filteredSchedules = useMemo(() => schedules?.filter((schedule) => (
    schedule.atividade.startsWith(selectedActivity)
  )), [schedules, selectedActivity]);

  function handleDateChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDate(event.target.value);
  }

  function handleTryAgain() {
    getScheduleList();
  }

  return {
    isLoading,
    doesListApiHasError,
    schedules,
    dateBeingShown,
    getScheduleList,
    dates,
    specificDateModalShow,
    setSpecificDateModalShow,
    selectedDate,
    handleDateChange,
    activitiesList,
    filteredSchedules,
    setSelectedActitvity,
    selectedActivity,
    kidsModalShow,
    setKidsModalShow,
    scheduleKidsListBeingShow,
    setScheduleKidsListBeingShow,
    handleTryAgain,
    warnings,
    warningsModalShow,
    setWarningsModalShow,
  };
}
