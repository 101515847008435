import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Card,
} from './styles';
import classLink from '../../../../assets/images/icons/classLink.svg';
import kidsIcon from '../../../../assets/images/icons/kids.svg';
import { ScheduleKidsListBeingShowType, SchedulesType } from '../../types';
import { Dispatch, SetStateAction } from 'react';

interface ListInterface {
  filteredList: SchedulesType | [];
  setKidsModalShow: Dispatch<SetStateAction<boolean>>;
  setScheduleKidsListBeingShow: Dispatch<SetStateAction<ScheduleKidsListBeingShowType>>;
}

export default function List({
  filteredList,
  setKidsModalShow,
  setScheduleKidsListBeingShow,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((appointment) => (
          <Col key={appointment.id}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: false }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{appointment.atividade}</strong>
                    <small>{appointment.horario}</small>
                  </div>
                  <span>
                    {appointment.criancas.length}
                    {' '}
                    {appointment.criancas.length == 1 ? 'criança' : 'crianças'}
                  </span>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => {
                      setKidsModalShow(true);
                      setScheduleKidsListBeingShow({
                        title: `${appointment.atividade} - ${appointment.horario}`,
                        list: appointment.criancas,
                      });
                    }}
                  >
                    <img src={kidsIcon} alt="kidsList" title="Visualizar lista de crianças" className="primaryColor" />
                  </button>
                  <a href={appointment.link} target="_blank" rel="noreferrer">
                    <img src={classLink} alt="linkAulaZoom" title="Acessar aula" />
                  </a>
                </div>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
