/* eslint-disable max-len */
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import ChangeDateModal from './components/ChangeDateModal';
import ListHeader from './components/header';
import KidsAtScheduleModal from './components/KidsAtScheduleModal';
import List from './components/list';
import WarningsModal from './components/WarningsModal';
import useSchedules from './useSchedules';

export default function Schedules() {
  const {
    isLoading,
    doesListApiHasError,
    schedules,
    dateBeingShown,
    getScheduleList,
    dates,
    selectedDate,
    specificDateModalShow,
    setSpecificDateModalShow,
    handleDateChange,
    handleTryAgain,
    activitiesList,
    filteredSchedules,
    setSelectedActitvity,
    selectedActivity,
    kidsModalShow,
    setKidsModalShow,
    scheduleKidsListBeingShow,
    setScheduleKidsListBeingShow,
    warnings,
    warningsModalShow,
    setWarningsModalShow,
  } = useSchedules();

  const listLength = schedules.length;
  const filteredListLength = filteredSchedules.length;
  const hasSchedules = listLength !== 0 && !!schedules;
  const isListEmpty = !doesListApiHasError && (!isLoading && filteredListLength === 0);

  const appointmentsQuantityAtEachClass = filteredSchedules.map((schedule) => schedule.criancas.length);
  const appointmentsQuantity = appointmentsQuantityAtEachClass.reduce((acc, cur) => acc + cur, 0);

  const selectedDay = selectedDate.split('-')[2];
  const selectedMonth = selectedDate.split('-')[1];
  const selectedYear = selectedDate.split('-')[0];
  const formattedSelectedDate = `${selectedDay}/${selectedMonth}/${selectedYear}`;

  return (
    <>
      <Sidebar active="Schedules" />
      <Transitions>
        <Loader isLoading={isLoading} />

        <ListHeader
          doesListExists={hasSchedules}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          appointmentsQuantity={appointmentsQuantity}
          singularLabel="aula"
          pluralLabel="aulas"
          handleDateChange={getScheduleList}
          dateBeingShown={dateBeingShown}
          dates={dates}
          handleModalSpecificDateShow={() => setSpecificDateModalShow(true)}
          activitiesList={activitiesList}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActitvity}
          warnings={warnings}
          setWarningsModalShow={setWarningsModalShow}
        />

        {hasSchedules && (
          <List
            filteredList={filteredSchedules}
            setKidsModalShow={setKidsModalShow}
            setScheduleKidsListBeingShow={setScheduleKidsListBeingShow}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum agendamento no dia
                {' '}
                <strong>{dateBeingShown}</strong>
                {selectedActivity && (
                  <>
                    {' '}
                    para a atividade
                    {' '}
                    <strong>{selectedActivity}</strong>
                    Tente mudar a data ou a atividade a selecionada.
                  </>
                )}
                {!selectedActivity && (
                  <>
                    {'. '}
                    Por favor, tente mudar a data.
                  </>
                )}
              </>
            )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
              Ocorreu um erro ao obter os agendamentos.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
              .
              </>
            )}
          />
        )}

        <WarningsModal
          warnings={warnings}
          warningsModalShow={warningsModalShow}
          setWarningsModalShow={setWarningsModalShow}
        />

        <ChangeDateModal
          specificDateModalShow={specificDateModalShow}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          setSpecificDateModalShow={setSpecificDateModalShow}
          getScheduleList={getScheduleList}
          formattedSelectedDate={formattedSelectedDate}
        />

        {!isLoading && (
          <KidsAtScheduleModal
            kidsModalShow={kidsModalShow}
            setKidsModalShow={setKidsModalShow}
            scheduleKidsListBeingShow={scheduleKidsListBeingShow}
          />
        )}
      </Transitions>
    </>
  );
}
